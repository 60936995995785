import { Skeleton, Stack } from "@mui/material";
import useIsDesktop from "desktop/useIsDesktop";
import { graphql, useStaticQuery } from "gatsby";

import { usePensionDetail } from "../../queryHooks";
import cs from "../../translations/cs.json";
import { PensionContractType } from "../../types/contracts";
import { useDrawer } from "../Drawer/context";
import { BgImage } from "../Image/BgImage";
import { TriggerButton } from "../Link";
import PensionCalculatorModal from "../PensionCalculator/Modal";
import { HeadingXL } from "../Typography";

const PensionNovelBanner = ({
	id,
	type,
	url,
}: {
	id: string;
	type: PensionContractType;
	url: string;
}): JSX.Element => {
	const bannerImage = useStaticQuery(
		graphql`
			query DocumentManagerTranslation {
				documents: file(relativePath: { eq: "novel-banner.png" }) {
					childImageSharp {
						fixed(quality: 90) {
							...GatsbyImageSharpFixed_withWebp
						}
					}
				}
			}
		`
	);

	const image = bannerImage.documents.childImageSharp.fixed;

	const IsDesktop = useIsDesktop();

	const { data, isSuccess } = usePensionDetail({ id, type });

	const { showDrawer, setDrawerContent } = useDrawer();

	if (isSuccess)
		return (
			<BgImage
				image={IsDesktop ? image : undefined}
				style={{
					backgroundPosition: "8px 8px",
					backgroundSize: "95px 80px",
				}}
			>
				<Stack
					sx={{
						justifyContent: "flex-end",
						height: { md: 95 },
						p: { md: 3, xxs: 4 },
						border: "1px solid",
						borderColor: "grey.light",
						borderRadius: 2,
					}}
					direction={IsDesktop ? "row" : "column"}
				>
					<Stack sx={{ display: "flex", justifyContent: "center", mr: 9 }}>
						<HeadingXL sx={{ maxWidth: 450, mb: 0 }}>
							{cs.pensionNovel.text1}{" "}
							<HeadingXL
								sx={{ display: "inline", mb: 0, color: "primary.dark" }}
							>
								{cs.pensionNovel.text1b}
							</HeadingXL>
							<HeadingXL sx={{ display: "inline", mb: 0 }}>
								{cs.pensionNovel.text1c}
							</HeadingXL>
						</HeadingXL>
					</Stack>
					<Stack sx={{ mt: 4, mr: 4, textAlign: "center" }} spacing={3}>
						<TriggerButton
							onClick={() => {
								showDrawer();
								setDrawerContent(
									<PensionCalculatorModal
										changeContributionUrl={url}
										monthlyContribution={
											data?.contract.monthlyContributionClient
										}
									/>
								);
							}}
							variant="contained"
							size="medium"
						>
							{cs.pensionNovel.getMoreInfo}
						</TriggerButton>
					</Stack>
				</Stack>
			</BgImage>
		);
	else return <Skeleton variant="rounded" height={100} width="100%" />;
};

export default PensionNovelBanner;
