import { Stack } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { useState } from "react";

import { useCookieConsents } from "../../../context/CookieContext";
import cs from "../../../translations/cs.json";
import { CookieCollapsible } from "../../Collapsible";
import Switch from "../../Form/Input/Switch";
import ArrowIconLeft from "../../Icons/ArrowLeft";
import { TriggerButton } from "../../Link";
import { BodyTextM, BodyTextS } from "../../Typography";
import { ICookieFormValues } from ".";
import {
	cookieCategory,
	cookies,
	marketingCookieIds,
	statisticalCookieIds,
} from "./CookieIds";
import useIsDesktop from "desktop/useIsDesktop";


interface ICookieForm {
	onAllowSelected: (values: ICookieFormValues) => void;
	onAllowAll: () => void;
	onAllowNecesary: () => void;
	floatingWindow: boolean;
	formikRef?: React.MutableRefObject<
		FormikProps<{
			statistical: boolean;
			marketing: boolean;
		}>
	>;

}

const categoryToCookieIds = {
	statistical: statisticalCookieIds,
	marketing: marketingCookieIds,
};

export const CookieForm = ({ onAllowSelected, formikRef, onAllowAll, onAllowNecesary, floatingWindow}: ICookieForm) => {
	const [expandedCategory, setCategory] = useState<cookieCategory | null>(null);
	const { data, isLoading } = useCookieConsents();
	const isDesktop = useIsDesktop();
	const [clickedProfileButton, setClickedProfileButton] = useState<string | null>(null);

	return (
		<Formik
			enableReinitialize
			initialValues={{
				statistical: data.allowedCookies.includes(cookies.appInsights),
				marketing: data.allowedCookies.includes(cookies.googleAds),
			}}
			onSubmit={(values, actions) => {
				actions.setSubmitting(false);
				onAllowSelected(values);
			}}
		>
			{(formikHelpers) => {
				if (formikRef) {
					formikRef.current = formikHelpers;
				}
				return (
					<Form>
						<Stack
							spacing={4}
							sx={{
								pt: floatingWindow ? 4 : 0,
								borderTop: floatingWindow ? "1px solid" : "none",
								borderColor: "grey.light",
							}}
						>
							<Stack spacing={3}>
								{expandedCategory ? (
									<Stack
										direction="row"
										alignItems="center"
										spacing={3}
										onClick={() => setCategory(null)}
										sx={{ cursor: "pointer" }}
									>
										<ArrowIconLeft sx={{ color: "primary.main" }} />
										<BodyTextM strong>
											{cs.cookies.categories[expandedCategory].label}{" "}
											{cs.cookies.dialog.cookies}
										</BodyTextM>
									</Stack>
								) : (
									<BodyTextM strong>
										{cs.cookies.dialog.consentPreSettings}
									</BodyTextM>
								)}
								{expandedCategory ? (
									<>
										{categoryToCookieIds[expandedCategory].map((cookieId) => (
											<CookieCollapsible
												key={cookieId}
												headline={cs.cookies.cookieDetails[cookieId].label}
											>
												<BodyTextS margin={0}>
													{cs.cookies.cookieDetails[cookieId].description}
												</BodyTextS>
												<Stack spacing='0' display='inline'>	
													<BodyTextS strong >{cs.cookies.cookieDetails.expirationDate}</BodyTextS> <BodyTextS>{cs.cookies.cookieDetails[cookieId].expirationDate}</BodyTextS><br/>
													<BodyTextS strong >{cs.cookies.cookieDetails.thirdParty}</BodyTextS> <BodyTextS>{cs.cookies.cookieDetails[cookieId].thirdParty}</BodyTextS><br/>
													<BodyTextS strong >{cs.cookies.cookieDetails.type} </BodyTextS> <BodyTextS>{cs.cookies.cookieDetails[cookieId].type}</BodyTextS>
												</Stack>
											</CookieCollapsible>
										))}
									</>
								) : (
									<>
										<CookieCollapsible
											headline={cs.cookies.categories.mandatory.label}
											sx={{ width: "100%" }}
											selector={"Vždy aktivní"}
										>
											<BodyTextM>
												{cs.cookies.categories.mandatory.description}
											</BodyTextM>
										</CookieCollapsible>
										<CookieCollapsible
											headline={cs.cookies.categories.statistical.label}
											sx={{ width: "100%" }}
											selector={
												<Switch
													name={"statistical"}
													sx={{
														width: "min-content",
														mr: 0,
														pr: 0,
														"&& .MuiSwitch-root": { mr: 0 },
													}}
												/>
											}
										>
											<BodyTextM>
												{cs.cookies.categories.statistical.description}
											</BodyTextM>
											<TriggerButton
												variant="text-light"
												onClick={() => setCategory("statistical")}
												size="small"
											>
												{cs.cookies.dialog.cookieDetails}
											</TriggerButton>
										</CookieCollapsible>
										<CookieCollapsible
											headline={cs.cookies.categories.marketing.label}
											sx={{ width: "100%" }}
											selector={
												<Switch
													name={"marketing"}
													sx={{
														width: "min-content",
														mr: 0,
														pr: 0,
														"&& .MuiSwitch-root": { mr: 0 },
													}}
												/>
											}
										>
											<BodyTextM>
												{cs.cookies.categories.marketing.description}
											</BodyTextM>
											<TriggerButton
												variant="text-light"
												onClick={() => setCategory("marketing")}
												size="small"
											>
												{cs.cookies.dialog.cookieDetails}
											</TriggerButton>
										</CookieCollapsible>
									</>
								)}
							</Stack>
							{floatingWindow ? (<TriggerButton
								type="submit"
								variant="contained"
								sx={{
									width: { md: "min-content", xxs: "50%" },
									maxWidth: "15rem",
									py: { md: 3, xxs: "1rem" },
									px: 4,
									whiteSpace: "nowrap",
								}}
								disableElevation={true}
								size="small"
							>
								{cs.cookies.dialog.allowSelected}
							</TriggerButton>) : (
							<Stack direction= {isDesktop ? "row":"column"} spacing={3} sx={{ display: 'flex', justifyContent: 'space-between' }} >
							{onAllowNecesary &&
								<TriggerButton 								
									variant="outlined"
									sx={{
										flexGrow: 1,
										py: { md: 3, xxs: '1rem' },
										px: 2,
										whiteSpace: "pre-line",
									}}
									disableElevation={true}
									disabled={isLoading}
									size="small"
									onClickCapture={() => setClickedProfileButton("allowNecesary")} 
									onClick={onAllowNecesary}>
										{isLoading && clickedProfileButton === 'allowNecesary' ? cs.global.processingWithDots : cs.cookies.dialog.allowNeccessary}
								</TriggerButton>}
								<TriggerButton
									type="submit"
									variant="contained"
									sx={{
										flexGrow: 2,
										py: { md: 3, xxs: '1rem' },
										px: 2,
										whiteSpace: "pre-line",
									}}
									disableElevation={true}
									disabled={isLoading}
									size="small"
									onClick={() => setClickedProfileButton("allowSelected")}
								>
									{isLoading && clickedProfileButton === 'allowSelected' ? cs.global.processingWithDots : cs.cookies.dialog.saveMyChanges}
								</TriggerButton>
								{onAllowAll && 
								<TriggerButton 								
									variant="contained"
									sx={{
										flexGrow: 1,
										py: { md: 3, xxs: '1rem' },
										px: 2,
										whiteSpace: "pre-line",
									}}
									disableElevation={true}
									disabled={isLoading}
									size="small" 
									onClickCapture={() => setClickedProfileButton("allowAll")}
									onClick={onAllowAll}>
										{isLoading && clickedProfileButton === 'allowAll' ? cs.global.processingWithDots : cs.cookies.dialog.allowAll}
								</TriggerButton>}
							</Stack>)}
						</Stack>
					</Form>
				);
			}}
		</Formik>
	);
};
