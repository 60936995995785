import SvgIcon from "@mui/material/SvgIcon";

const CookieIcon = (props): JSX.Element => {
	return (
		<SvgIcon width="253" height="253" viewBox="0 0 190 190" fill="none" {...props}>
			<path d="M80.5 24.45C23.95 37.7 3.8 105.3 44.4 145.5c45.7 45.2 121.75 14 122.05-50 .05-18.3-2.7-21.9-10.45-13.5-5.6 6.05-10.85 5.4-15.5-2-2.45-3.85-2.65-3.95-6.9-3.35-11.9 1.6-21.9-8.3-20.25-20.15.6-4.4.5-4.55-3.35-7-7.4-4.65-8.05-9.9-2-15.5 4.45-4.1 5.1-7.35 1.85-9.1-3.4-1.8-22.35-2.1-29.35-.45m16 9.3c-2.85 6.95-.4 17.3 4.9 20.8 2.1 1.35 2.6 2.45 2.6 5.9 0 12.15 11.45 23.9 24.6 25.25 4.25.45 5.7 1.1 6.95 3.05 3.45 5.2 13.85 7.55 20.85 4.7 1.85-.75 1.9-.4 1.25 6.95-4.45 52.95-69.35 76.55-107 38.95C19.95 108.6 28.6 57.4 67.7 38.4c11.7-5.7 30.45-8.7 28.8-4.65"/>
			<path d="M78.9 55.9C73.35 61.5 77.2 71 85 71c4.3 0 9-4.7 9-9s-4.7-9-9-9c-2.15 0-4.1.95-6.1 2.9m-26 33C47.35 94.5 51.2 104 59 104c4.3 0 9-4.7 9-9 0-7.8-9.5-11.65-15.1-6.1m52.3 4.3c-2.8 2.8-.7 7.8 3.3 7.8 1.15 0 2.65-.55 3.3-1.2s1.2-2.15 1.2-3.3-.55-2.65-1.2-3.3-2.15-1.2-3.3-1.2-2.65.55-3.3 1.2m27 21c-.65.65-1.2 2.15-1.2 3.3s.55 2.65 1.2 3.3 2.15 1.2 3.3 1.2 2.65-.55 3.3-1.2 1.2-2.15 1.2-3.3-.55-2.65-1.2-3.3-2.15-1.2-3.3-1.2-2.65.55-3.3 1.2m-62 1c-2.8 2.8-.7 7.8 3.3 7.8 1.15 0 2.65-.55 3.3-1.2s1.2-2.15 1.2-3.3-.55-2.65-1.2-3.3-2.15-1.2-3.3-1.2-2.65.55-3.3 1.2m26.7 16.7c-5.55 5.6-1.7 15.1 6.1 15.1 4.3 0 9-4.7 9-9s-4.7-9-9-9c-2.15 0-4.1.95-6.1 2.9m33.5-107.45c-.3.8-.4 2.25-.2 3.25.45 2.5 6.15 2.5 6.6 0 .75-3.7-5.1-6.7-6.4-3.25M151 33c-2.25 2.25-2.45 3.3-.95 6.05 2.1 3.95 8.95 1.65 8.95-3 0-1.35-3.65-5.05-5-5.05-.55 0-1.9.9-3 2m-20.9 19.4c-2.15 2.55-.95 5.1 2.4 5.1 2.65 0 3-.35 3-3 0-3.45-3.25-4.7-5.4-2.1m23.3 5.05c-.3.8-.4 2.25-.2 3.25.45 2.5 6.15 2.5 6.6 0 .75-3.7-5.1-6.7-6.4-3.25"/>
		</SvgIcon>
	);
};

export default CookieIcon;
