import { Stack } from "@mui/material";

import useIsDesktop from "../../../desktop/useIsDesktop";
import cs from "../../../translations/cs.json";
import { TriggerButton } from "../../Link";
import { BodyTextM, HeadingM } from "../../Typography";

interface ICookieDialog {
	initial: boolean;
	floatingWindow: boolean;
	onExpand: () => void;
	onAllowAll: () => void;
	onAllowNecesary: () => void;
}

export const CookieDialog = ({
	initial,
	floatingWindow,
	onExpand,
	onAllowAll,
	onAllowNecesary,
}: ICookieDialog): JSX.Element => {
	const isDesktop = useIsDesktop();

	return (
		<Stack sx={{ display: 'flex', justifyContent: 'space-between' }}>
			<HeadingM sx={{ mb: floatingWindow ? '2rem' : 0 }} withoutScale>
				{floatingWindow ? cs.cookies.dialog.pageUsesCookies : ""}
			</HeadingM>
			<BodyTextM
				sx={{ mb: 4, textAlign: "justify" }}
				dangerouslySetInnerHTML={{
					__html: cs.cookies.dialog.cookiesServicesImprovementProfile,
				}}
			/>
			{initial && !isDesktop && (
				<TriggerButton
					onClick={onExpand}
					variant="text-light"
					size="small"
					sx={{
						justifyContent: "start",
						mb: 5,
						p: 0,
						textDecoration: "underline",
					}}
				>
					{cs.cookies.dialog.manageOptions}
				</TriggerButton>
			)}
			
			{floatingWindow && 
			<Stack sx={{ display: 'flex', justifyContent: 'flex-start', flexDirection: "row", gap: '1rem'}}>
				 <TriggerButton
					variant="contained"
					sx={{
						flexGrow: 0.25,
						py: { md: 3, xxs: '1rem' },
						px: 4,
					}}
					disableElevation={true}
					onClick={onAllowAll}
					size="small"
				>
					{cs.cookies.dialog.allowAll}
				</TriggerButton>
				{initial && (
					<TriggerButton
						variant="outlined"
						sx={{
							flexGrow: 2,
							py: 3,
							px: 4,
						}}
						disableElevation={true}
						onClick={onAllowNecesary}
						size="small"
					>
						{cs.cookies.dialog.allowNeccessary}
					</TriggerButton>
				)}
				
				{initial && isDesktop && (
					<div style={{ display: 'flex', marginLeft: '5rem' }}>	
						<TriggerButton
							variant="text-light"
							sx={{
								alignSelf: "center",
								ml: "auto !important",
								"&:hover": {
									textDecoration: "underline",
								},
							}}
							size="small"
							onClick={onExpand}
						>
							{cs.cookies.dialog.manageOptions}
						</TriggerButton>
					</div>	
				)}
			</Stack>}
		</Stack>
	);
};
