import { Stack } from "@mui/material";
import { useLocation } from "@reach/router";
import useIsDesktop from "desktop/useIsDesktop";
import { ReactNode } from "react";

import { useCookieConsents } from "../../context/CookieContext";
import CookieDrawer from "../Drawer/CookieDrawer";
import { InfoBannersWrapper } from "../InfoBanners";
import { HeadingXL } from "../Typography";
import { BackgroundImageByPage, TitleByPage } from ".";
import { isFuture, parseISO} from "date-fns";

const DesktopBodyWrapper = ({
	pageProps,
	children,
	BackgroundImage,
}: LayoutBodyProps & { BackgroundImage?: any }): JSX.Element => {
	return (
		<Stack
			id="desktop-content-layout-body"
			sx={{
				position: BackgroundImage ? "relative" : undefined,
				justifyContent: pageProps.verticalCenter ? "center" : "flex-start",
				alignSelf: pageProps.verticalCenter ? "stretch" : "flex-start",
				width: "100%",
				maxWidth: "962px",
				mx: "auto",
			}}
		>
			<InfoBannersWrapper pageName={pageProps.name} showCoreSystemDownWarning />
			{children}
		</Stack>
	);
};

export interface LayoutBodyProps {
	pageProps: {
		name: string;
		verticalCenter?: boolean;
		appBar?: string;
	};
	children: ReactNode;
}

const getTitle = (page: string): string | undefined => {
	if (!page) {
		return;
	}
	return page.includes("profile") ? TitleByPage.profile : TitleByPage[page];
};

const LayoutBody = ({ pageProps, children }: LayoutBodyProps): JSX.Element => {
	const isDesktop = useIsDesktop();
	const { data: cookieData, displayCookieForm } = useCookieConsents();
	const cookieHasExpiered = cookieData.expires ? !isFuture(parseISO(cookieData.expires)): false;
	const title = getTitle(pageProps.name);
	const BackgroundImage: any = BackgroundImageByPage[pageProps.name];

	const location = useLocation();

	const desktopContent = (
		<>
			{title && <HeadingXL>{title}</HeadingXL>}
			{pageProps.verticalCenter ? (
				<Stack sx={{ justifyContent: "center" }}>{children}</Stack>
			) : (
				children
			)}
		</>
	);

	return (
		<Stack
			id="layout-body-container"
			sx={{
				width: "100%",
				p: { md: 7, xxs: 4 },
			}}
		>
			{isDesktop ? (
				<>
					{BackgroundImage ? (
						<>
							<DesktopBodyWrapper
								pageProps={pageProps}
								BackgroundImage={BackgroundImage}
							>
								<BackgroundImage>{desktopContent}</BackgroundImage>
							</DesktopBodyWrapper>
						</>
					) : (
						<DesktopBodyWrapper pageProps={pageProps}>
							{desktopContent}
						</DesktopBodyWrapper>
					)}
				</>
			) : (
				<Stack gap={2}>
					{pageProps.name === "contracts" && (
						<InfoBannersWrapper
							pageName={pageProps.name}
							showCoreSystemDownWarning={false}
						/>
					)}
					{children}
				</Stack>
			)}
			{(!cookieData.consentSet || displayCookieForm || cookieHasExpiered) &&
				location.pathname !== "/penzijni-kalkulacka/" && <CookieDrawer floating={true}/>}
		</Stack>
	);
};

export default LayoutBody;
