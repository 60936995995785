import { Box, IconButton, Typography } from "@mui/material";
import CookieIcon from "../../Icons/Cookie";
import useIsDesktop from "desktop/useIsDesktop";
import cs from "../../../translations/cs.json";

interface CookieButtonProps {
  onClick: () => void;
}

const CookieButton: React.FC<CookieButtonProps> = ({ onClick }) => {
    const isDesktop = useIsDesktop();
    return (
        <>
        {isDesktop ? (
            <Box
                sx={{
                position: 'fixed',
                bottom: '6%',
                left: '90%',
                borderRadius: '50%',
                backgroundColor: 'white',
                boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.2)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '5rem',
                height: '5rem',
                }}
            >
                <IconButton
                    sx={{
                        borderRadius: '50%',
                        padding: '1rem',
                        '&:hover': {
                        backgroundColor: 'extreme-light-gray',
                        },
                    }}
                    onClick={onClick}
                    >
                    <CookieIcon sx={{ width: '3rem', height: '3rem', color: 'primary.light' }} />
                </IconButton>
            </Box>
        ) : (
            <Box
                sx={{
                    position: 'absolute',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    display: 'inline-flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    textDecoration: 'underline'
                }}
                onClick={onClick}
                >
                <CookieIcon sx={{ width: '3rem', height: '3rem', color: 'primary.light', marginRight: '0.5rem' }} />
                <Typography sx={{ textAlign: 'center' }}>
                    {cs.cookies.dialog.changeCookies}
                </Typography>
            </Box>
        )}
        </>
    );
};

export default CookieButton;