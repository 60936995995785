import { Card, CardContent, Stack } from "@mui/material";
import useIsDesktop from "desktop/useIsDesktop";
import { motion } from "framer-motion";
import { useRef, useState } from "react";

import { useCookieConsents } from "../../../context/CookieContext";
import Modal from "../Modal";
import { CookieDialog } from "./CookieDialog";
import { CookieForm } from "./CookieForm";
import {
	cookieId,
	mandatoryCookieIds,
	marketingCookieIds,
	statisticalCookieIds,
} from "./CookieIds";

export interface ICookieFormValues {
	statistical: boolean;
	marketing: boolean;
}

interface CookieDrawerProps {
	floating: boolean;
	onAttentionSuccess?: () => void;
  }
  
const CookieDrawer: React.FC<CookieDrawerProps> = ({ floating, onAttentionSuccess }) => {
	const [isExpanded, setExpanded] = useState(!floating);
	const isDesktop = useIsDesktop();
	const { setCookieConsent } = useCookieConsents();
const formikRef = useRef();

	const allowAllCookies = () => {
		const allowed = [
			...mandatoryCookieIds,
			...statisticalCookieIds,
			...marketingCookieIds,
		];
		formikRef.current?.setValues({ statistical: true, marketing: true });
		void setCookieConsent(allowed);
		if(!floating && onAttentionSuccess) onAttentionSuccess();
	};

	const allowSelected = (values: ICookieFormValues) => {
		const allowed: cookieId[] = [
			mandatoryCookieIds,
			values.statistical ? statisticalCookieIds : [],
			values.marketing ? marketingCookieIds : [],
		].flat();
		void setCookieConsent(allowed);
		if(!floating && onAttentionSuccess) onAttentionSuccess();
	};

	const allowNecesary = () => {
		void setCookieConsent(mandatoryCookieIds);
		if(!floating && onAttentionSuccess) onAttentionSuccess();
	};

	const WrapperComponent =  floating ? Card : "div";
	const WrapperComponentMobile =  floating ? Modal : "div";


	if (isDesktop)
		return (
			<WrapperComponent
				sx={{
					position: "fixed",
					right: "50px",
					bottom: "50px",
					maxWidth: "550px",
				}}   
			>
				<CardContent sx={{ p: floating ? 5 : 0}}>
					<Stack spacing={5}>
						<CookieDialog
							onAllowAll={allowAllCookies}
							onAllowNecesary={allowNecesary}
							initial={!isExpanded}
							onExpand={() => setExpanded(true)}
							floatingWindow={floating}
						/>

						{isExpanded && (
							<motion.div
								initial={{
									height: 0,
								}}
								animate={{
									height: "auto",
									transition: {
										type: "linear",
									},
								}}
								exit={{
									height: 0,
								}}
							>
								<CookieForm onAllowSelected={allowSelected} formikRef={formikRef} onAllowAll={allowAllCookies} onAllowNecesary={allowNecesary} floatingWindow={floating}/>
							</motion.div>
						)}
					</Stack>
				</CardContent>
			</WrapperComponent>
		);

	return (
		<WrapperComponentMobile
			open={true}
			sx={{
				display: "flex",
				justifyContent: "flex-end !important",
				alignItems: "end",
			}}
		>
			<Stack
				sx={{
					py: floating ? 5 : 0,
					px: 4,
					mb: floating ? 5 : 0,
					overflow: "scroll",
				}}
				spacing={5}
			>
				<CookieDialog
					onAllowAll={allowAllCookies}
					onAllowNecesary={allowNecesary}
					initial={!isExpanded}
					onExpand={() => setExpanded(true)}
					floatingWindow={floating}
				/>
				{isExpanded && (
					<motion.div
						initial={{
							height: 0,
						}}
						animate={{
							height: "auto",
							transition: {
								type: "linear",
							},
						}}
						exit={{
							height: 0,
						}}
					>
						<CookieForm onAllowSelected={allowSelected} onAllowAll={allowAllCookies} onAllowNecesary={allowNecesary} floatingWindow={floating}/>
					</motion.div>
				)}
			</Stack>
		</WrapperComponentMobile>
	);
};

export default CookieDrawer;
