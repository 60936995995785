import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import useIsDesktop from "desktop/useIsDesktop";

import { useProfile } from "../../queryHooks";
import Address from "../Icons/Address";
import Contracts from "../Icons/Contracts";
import Files from "../Icons/Files";
import Pencil from "../Icons/Pencil";
import Person from "../Icons/Person";
import { InternalAttentionLink } from "../Link/InternalButtonLink";
import LogoutButton from "../LogoutButton";
import { HeadingL } from "../Typography";
import CookieIcon from "../Icons/Cookie";
import cs from "../../translations/cs.json";

export default function Menu(): JSX.Element {
	const isD = useIsDesktop();
	const result = useProfile();


	const { firstName, lastName } = result.data?.client || {
		firstName: null,
		lastName: null,
	};

	return (
		<Stack
			spacing={7}
			sx={{
				px: isD ? undefined : 4,
			}}
		>
			<Stack>
				<HeadingL withoutScale>
					{result.isSuccess ? `${firstName} ${lastName}` : <Skeleton />}
				</HeadingL>
				<Stack spacing={3}>
					<InternalAttentionLink
						to="/profil/moje-udaje/"
						startIcon={<Person sx={{ width: 18 }} />}
					>
						Moje údaje
					</InternalAttentionLink>
					<InternalAttentionLink
						to="/profil/adresy/"
						startIcon={<Address sx={{ width: 17 }} />}
					>
						Moje adresy
					</InternalAttentionLink>
					<InternalAttentionLink
						to="/profil/zmeny/"
						startIcon={<Pencil sx={{ width: "2.1rem", height: "2.1rem" }} />}
					>
						Seznam změn
					</InternalAttentionLink>
				</Stack>
			</Stack>
			<Stack>
				<HeadingL withoutScale>Ostatní</HeadingL>
				<Stack spacing={3}>
					<InternalAttentionLink
						to="/profil/souhlas-s-elektronickou-komunikaci-a-kontraktaci/"
						startIcon={<Contracts sx={{ width: 24 }} />}
					>
						{cs.profile.communicationContractation.header}
					</InternalAttentionLink>
					<InternalAttentionLink
						to="/profil/marketingovy-souhlas/"
						startIcon={<Contracts sx={{ width: 24 }} />}
					>
						{cs.profile.marketing.header}
					</InternalAttentionLink>
					<InternalAttentionLink
						to="/profil/ochrana-osobich-udaju/"
						startIcon={<Files sx={{ width: 20 }} />}
					>
						{cs.profile.other.item.privacyPolicy}
					</InternalAttentionLink>
					<InternalAttentionLink
						to="/profil/cookies/"
						startIcon={<CookieIcon sx={{ width: 24, height: 24 }} />}	
					>
						{cs.profile.cookies.header}
					</InternalAttentionLink>
					
				</Stack>
			</Stack>
			{!isD && <LogoutButton />}
		</Stack>
	);
}
