import { Alert } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import useIsDesktop from "desktop/useIsDesktop";
import { isEmpty } from "lodash";

import { usePrimaryAPIClient } from "../../context/PrimaryAPIClient";
import { useQueryKeyFactory } from "../../context/QueryKeyProvider";
import { useHasSPError } from "../../context/SPErrorContext";
import { useIsUserLoggedIn } from "../../hooks/useIsUserLoggedIn";
import cs from "../../translations/cs.json";
import { BannerWrapperDesktop, BannerWrapperMobile } from "../Layout";
import PensionNovelBannerWrapper from "../PensionNovelBanner/Wrapper";
import { BodyTextM } from "../Typography";

const BannerComponent = ({
	severity,
	value,
}: {
	severity: string;
	value: string;
}) => (
	<Alert
		severity={severity as "error" | "warning" | "info" | "success"}
		sx={{
			zIndex: 999,
			pr: 4,
			pl: 4,
		}}
	>
		<BodyTextM dangerouslySetInnerHTML={{ __html: value }}></BodyTextM>
	</Alert>
);

type InfoBannersProps = {
	showCoreSystemDownWarning: boolean;
};

const InfoBanners = ({ showCoreSystemDownWarning }: InfoBannersProps) => {
	const { hasError: isSpError } = useHasSPError();
	const isDesktop = useIsDesktop();
	const { bannersApi } = usePrimaryAPIClient();
	const isLoggedUser = useIsUserLoggedIn();
	const { createQueryKey } = useQueryKeyFactory();

	const { data } = useQuery({
		queryKey: createQueryKey({
			key: ["bannersApi.getCmsWarningsGet"],
			includeUserIdentifier: false,
		}),
		queryFn: () => bannersApi.getCmsWarningsGet(),
		enabled: isLoggedUser,
	});

	const WrapperComponent = isDesktop
		? BannerWrapperDesktop
		: BannerWrapperMobile;

	if (!isLoggedUser) return null;

	return (
		<WrapperComponent>
			{data &&
				Object.entries(data).map(([key, value]) => {
					if (!value || value === "") return null;

					if (data?.error && !isEmpty(data?.error) && key !== "error")
						return null;

					return <BannerComponent key={key} severity={key} value={value} />;
				})}

			{isSpError && showCoreSystemDownWarning && !data?.error && (
				<BannerComponent
					severity={"error"}
					value={cs.global.starPensionUnavailableWarning}
				/>
			)}
			<PensionNovelBannerWrapper />
		</WrapperComponent>
	);
};

type InfoBannerWrapperProps = {
	pageName: string;
};

export const InfoBannersWrapper = ({
	pageName,
	showCoreSystemDownWarning,
}: InfoBannerWrapperProps & InfoBannersProps) => {
	if (pageName !== "contracts") return <></>;

	return <InfoBanners showCoreSystemDownWarning={showCoreSystemDownWarning} />;
};
